const sideMenu5 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fas fa-tachometer-alt' },
    { label: 'Cek Status', url: '/cek', icon: 'fa fa-list-ul' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'far fa-clock' },
    { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fa fa-archive' },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fa fa-users',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'far fa-circle' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fa fa-users' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fa fa-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'far fa-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'far fa-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'far fa-circle' },
            { label: 'Financial Report', url: '/finance/shipper', icon: 'far fa-circle' },
            // { label: 'Pergerakan<br>Saldo Shipper', url: '/saldo/approval', icon: 'fa fa-circle' },
        ]
    }
];
export default sideMenu5;