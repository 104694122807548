const sideMenu4 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fas fa-tachometer-alt' },
    { label: 'Cek Status', url: '/cek', icon: 'fa fa-list-ul' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'far fa-clock' },
    { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fa fa-archive' },
    { label: 'Payment', url: '/payment-admin/all', icon: 'fas fa-money-bill' },
    //   { label: 'Payment', url: '/payment', icon: 'fa fa-money-bill' },
    { label: 'Profil', url: '/profile', icon: 'far fa-user' },
    {
        label: 'Data Rekonsiliasi',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Rekonsiliasi', url: '/rekonsiliasi', icon: 'far fa-circle' },
            { label: 'Upload <br> Rekonsiliasi Shipper', url: '/rekonsiliasi/shipper/upload', icon: 'far fa-circle' },
            { label: 'Upload <br> Rekonsiliasi Kurir', url: '/rekonsiliasi/kurir/upload', icon: 'far fa-circle' },
            { label: 'Riwayat Rekonsiliasi', url: '/rekonsiliasi/riwayat', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fa fa-users',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'far fa-circle' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fa fa-users' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fa fa-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'far fa-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'far fa-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'far fa-circle' },
            { label: 'Financial Report', url: '/finance/shipper', icon: 'far fa-circle' },
            // { label: 'Pergerakan<br>Saldo Shipper', url: '/saldo/approval', icon: 'fa fa-circle' },
        ]
    },
    {
        label: 'Laporan',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Buku Besar', url: '/report/bukubesar', icon: 'fa fa-circle' },
            { label: 'Trial Balance', url: '/report/trialbalance', icon: 'fa fa-circle' },
            { label: 'Bospay', url: '/report/bospay', icon: 'fa fa-circle' },
            { label: 'Hutang Usaha (Kurir)', url: '/report/hutang/kurir', icon: 'fa fa-circle' },
            { label: 'Hutang COD (Shipper)', url: '/report/hutang/shipper', icon: 'fa fa-circle' },
            { label: 'Piutang', url: '/report/piutang-new', icon: 'fa fa-circle' },
            { label: 'PPN Masukan', url: '/report/ppn-masukan', icon: 'fa fa-circle' },
            { label: 'PPN Keluaran', url: '/report/ppn-keluaran', icon: 'fa fa-circle' },
            { label: 'Laba Rugi', url: '/report/labarugi', icon: 'fa fa-circle' },
            { label: 'Neraca', url: '/report/neraca', icon: 'fa fa-circle' },
            { label: 'Jurnal Entri', url: '/report/jurnal-entri', icon: 'fa fa-circle' },
            { label: 'Jurnal Group', url: '/report/jurnal-group', icon: 'fa fa-circle' },
            { label: 'Kewajaran', url: '/report/kewajaran', icon: 'fa fa-circle' },
        ]
    },
    {
        label: 'Ticket',
        url: '#',
        icon: 'fa fa-cog',
        menus: [
            { label: 'Claim', url: '/settings/tickets/claim', icon: 'far fa-circle' }
        ]
    },
    {
        label: 'Winpay',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Laporan Payment Gateway', url: '/report/winpay', icon: 'fa fa-circle' },
            { label: 'Upload Rekonsiliasi Winpay', url: '/rekonsiliasi/winpay', icon: 'fa fa-circle' }
        ]
    }
];
export default sideMenu4;