const sideMenu13 = [
    { label: 'Dashboard', url: '/dashboard-admin', icon: 'fas fa-tachometer-alt' },
    { label: 'Cek Status', url: '/cek', icon: 'fa fa-list-ul' },
    { label: 'Riwayat Kiriman', url: '/riwayat/admin/all', icon: 'far fa-clock' },
    { label: 'Arsip Riwayat Kiriman', url: '/arsip/riwayat', icon: 'fa fa-archive' },
    { label: 'Profil', url: '/profile', icon: 'far fa-user' },
    {
        label: 'Data Shipper',
        url: '#',
        icon: 'fa fa-users',
        menus: [
            { label: 'Shipper', url: '/shipper', icon: 'far fa-circle' },
            { label: 'Shipper CS', url: '/settings/user', icon: 'fa fa-users' },
            { label: 'Kiriman', url: '/shipper/recap', icon: 'fa fa-circle' },
            { label: 'Top-Up', url: '/saldo/topup/approval', icon: 'far fa-circle' },
            { label: 'Withdraw', url: '/saldo/withdraw/approval', icon: 'far fa-circle' },
            { label: 'Rekap Saldo', url: '/saldo/shipper', icon: 'far fa-circle' },
            { label: 'Verifikasi KTP', url: '/shipper/verification', icon: 'far fa-circle' },
        ]
    },
    //{ label: 'Buyer', url: '/buyer', icon: 'fa fa-users' },

    {
        label: 'Ticket',
        url: '#',
        icon: 'fa fa-cog',
        menus: [{
            label: 'Buat baru',
            url: () => {
                //this.$root.$emit('createTicket', 3);
            },
            icon: 'far fa-circle'
        },
        { label: 'List', url: '/settings/tickets/all', icon: 'far fa-circle' },
        { label: 'Claim', url: '/settings/tickets/claim', icon: 'far fa-circle' },
        ]
    },
    {
        label: 'Update Tracking',
        url: '#',
        icon: 'fa fa-list',
        menus: [
            { label: 'Cek Tracking <br> Kurir', url: '/tracking', icon: 'far fa-circle' },

        ]
    }

];
export default sideMenu13;